var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"supplier-order-table"},[_c('div',{staticClass:"d-flex mb-2 p-2 gap-7 border rounded bg-light-gray text-typography-primary"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('commons.reference'))+": "),(_vm.order.eventReferences)?_c('span',[_c('Button',{staticClass:"p-0 m-0",attrs:{"type":"link"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('document-open', _vm.order.eventReferences[0].documentId)}}},[_vm._v(" "+_vm._s(_vm.$t(`document.exports.schema.type.shortName.${_vm.order.eventReferences[0].document.type}`))+" "+_vm._s(_vm.order.eventReferences[0].reference)+" ")])],1):_c('span',[_vm._v("-")])]),_c('p',[_vm._v(" "+_vm._s(`${_vm.$t('commons.date')}: ${_vm.formatDate(_vm.order.date)}`)+" ")]),_c('p',[_vm._v(" "+_vm._s(`${_vm.$t('commons.total')}: ${_vm.formatMoney(_vm.order.netAmount)}`)+" ")])]),_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.order.products,"rounded":"","border":"","hover":false},scopedSlots:_vm._u([{key:"cell-sku",fn:function({
        rowData: {
          product: { sku },
        },
      }){return [_vm._v(" "+_vm._s(sku)+" ")]}},{key:"cell-product",fn:function({
        rowData: {
          product: { name },
        },
      }){return [_vm._v(_vm._s(name)+" ")]}},{key:"cell-quantity",fn:function({ rowData: { quantity } }){return [_vm._v(_vm._s(_vm.formatQuantity(quantity)))]}},{key:"cell-totalAmount",fn:function({ rowData: { totalAmount } }){return [_vm._v(_vm._s(_vm.formatMoney(totalAmount)))]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }