import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useLoading } from '@/modules/core';

import { EVENT_MAP_QUERY } from './graphql';

function isShowCounterQuantity({ products }) {
  return !!products.filter(({ quantity }) => quantity < 0).length;
}

function isShowMainQuantity({ products }) {
  return !!products.filter(({ quantity }) => quantity >= 0).length;
}

function isShowCounterPrice({ netAmount }) {
  return netAmount < 0;
}

function isShowMainPrice({ netAmount }) {
  return netAmount >= 0;
}

export function useSupplierActivity(variables) {
  const convertedVars = computed(() => ({
    businessId: variables.value.businessId,
    supplierId: variables.value.supplierId,
    from: variables.value.start.value,
    to: variables.value.end.value,
  }));

  const {
    result: queryResult,
    loading: queryLoading,
    refetch,
  } = useQuery(EVENT_MAP_QUERY, convertedVars, () => ({ enabled: !!convertedVars.value.businessId }));
  const data = computed(() => ({
    orders: queryResult.value?.orders?.nodes ?? [],
    deliveries: queryResult.value?.deliveries?.nodes ?? [],
    billings: queryResult.value?.billings?.nodes ?? [],
  }));
  const loading = useLoading(queryLoading, variables);

  const result = computed(() => {
    const orderItems = data.value.orders
      .map((order) => ({
        type: 'order',
        id: order.id,
        date: new Date(order.date).toISOString().split('T')[0],
        docRef: { type: '', documentNumber: '123', id: '123' },
        payload: order,
        document: order.source && order.source.document,
        showCounter: isShowCounterQuantity(order),
        showMain: isShowMainQuantity(order),
        linkedItems: [],
      }))
      .sort((i1, i2) => i1.date.localeCompare(i2.date) || i1.id.localeCompare(i2.id))
      .reverse();

    const orderColumn = {
      type: 'order',
      summery: {
        mainIssues: [{ text: '{count} orders', params: { count: data.value.orders.length } }],
        money: data.value.orders.reduce((total, order) => total + order.amount, 0),
        includeVAT: false,
      },
      items: orderItems,
    };

    const deliveryItems = data.value.deliveries
      .map((delivery) => ({
        type: 'delivery',
        id: delivery.id,
        date: new Date(delivery.date).toISOString().split('T')[0],
        payload: delivery,
        document: delivery.source?.document,
        showCounter: isShowCounterQuantity(delivery),
        showMain: isShowMainQuantity(delivery),
        linkedItems: delivery.orderIds,
      }))
      .sort(
        (i1, i2) =>
          i1.date.localeCompare(i2.date) || (i1.linkedItems[0] ? i1.linkedItems[0].localeCompare(i2.linkedItems[0]) : 0)
      )
      .reverse();

    const missingDeliveriesItems = data.value.billings.flatMap((billing) =>
      billing.deliveryRefs
        .filter(({ delivery }) => !delivery)
        .map(({ externalRef }) => ({
          type: 'delivery',
          id: externalRef,
          document: {
            documentNumber: externalRef,
            type: billing.source?.document.type,
          },
          isMissing: true,
          linkedItems: [],
        }))
    );

    const deliveryColumn = {
      type: 'delivery',
      summery: {
        mainIssues: [{ text: '{count} deliveries', params: { count: data.value.deliveries.length } }],
        items: data.value.deliveries.reduce((total, delivery) => total + delivery.products.length, 0),
      },
      items: [...missingDeliveriesItems, ...deliveryItems],
    };

    const billingItems = data.value.billings
      .map((billing) => ({
        type: 'billing',
        id: billing.id,
        date: new Date(billing.date).toISOString().split('T')[0],
        payload: {
          ...billing,
          related: billing.related.map((relatedBilling) => ({
            ...relatedBilling,
            document: relatedBilling.source?.document,
          })),
        },
        document: billing.source?.document,
        showCounter: isShowCounterPrice(billing),
        showMain: isShowMainPrice(billing),
        linkedItems: [
          ...billing.deliveryRefs.filter(({ delivery }) => !!delivery).map(({ delivery }) => delivery.id),
          ...missingDeliveriesItems
            .filter((missingDelivery) =>
              billing.deliveryRefs.some((ref) => ref.externalRef === missingDelivery.document.documentNumber)
            )
            .map((missingDelivery) => missingDelivery.id),
        ],
      }))
      .sort((i1, i2) => i1.date.localeCompare(i2.date))
      .reverse();

    const billingColumn = {
      type: 'billing',
      summery: {
        mainIssues: [
          { text: '{count} invoices', params: { count: data.value.billings.filter((b) => b.totalAmount >= 0).length } },
          {
            text: '{count} credit notes',
            params: { count: data.value.billings.filter((b) => b.totalAmount < 0).length },
          },
        ],
        money: data.value.billings.reduce((total, billing) => total + billing.totalAmount, 0),
        includeVAT: true,
      },
      items: billingItems,
    };

    return [orderColumn, deliveryColumn, billingColumn];
  });

  return {
    data,
    result,
    supplierId: computed(() => variables.supplierId),
    loading,
    refetch,
  };
}
