import { gql } from '@apollo/client/core';

export const EVENT_MAP_QUERY = gql`
  query getEventMap($businessId: ID!, $supplierId: ID, $from: ISODate, $to: ISODate) {
    orders(businessId: $businessId, supplierId: $supplierId, fromDate: $from, toDate: $to) {
      nodes {
        id
        date
        supplierId
        amount
        supplier {
          id
          name
        }
        itemsCount {
          ordered
          returnNotice
        }
        products {
          id
          productId
          product {
            id
            sku
            name
          }
          quantity
          price
          discount
          source {
            price {
              type
              additionalInfo {
                priceListDate
              }
              user {
                profile {
                  firstName
                  lastName
                }
              }
              term {
                id
                type
                pricingMethod
                priceListId
                rewardValue
                price
                priceIndexName
                updatedAt
              }
              document: documentNew2 {
                documentNumber
                type
              }
            }
            discount {
              type
              user {
                profile {
                  firstName
                  lastName
                }
              }
              term {
                id
                type
                pricingMethod
                priceListId
                rewardValue
                price
                priceIndexName
              }
              document {
                documentNumber
                type
              }
            }
          }
        }
        diffs {
          fieldPath
          customerValue
          supplierValue
        }
        differences {
          productIndex
          customerItemId
          product {
            id
            name
            sku
          }
          amount
          price {
            customerValue
            supplierValue
            solved
          }
          quantity {
            customerValue
            supplierValue
            solved
          }
          discount {
            customerValue
            supplierValue
            solved
          }
        }
        source {
          document: documentNew2 {
            id
            type
            documentNumber
          }
        }
      }
    }

    deliveries(businessId: $businessId, supplierId: $supplierId, fromDate: $from, toDate: $to) {
      nodes {
        id
        date
        supplierId
        products {
          productId
          quantity
        }
        diffs {
          product {
            id
          }
          supplierValue
        }
        imbalances {
          productId
        }
        orderIds
        source {
          document: documentNew2 {
            id
            type
            documentNumber
          }
        }
      }
    }

    billings(businessId: $businessId, supplierId: $supplierId, fromDate: $from, toDate: $to) {
      nodes {
        id
        date
        supplierId
        netAmount
        type
        taxAmount
        totalAmount
        deliveryRefs {
          externalRef
          delivery {
            id
          }
          date
        }
        related {
          id
          netAmount
          source {
            document: documentNew2 {
              id
              type
              documentNumber
            }
          }
          orderLinks {
            order {
              id
            }
            amount
          }
        }
        orderLinks {
          order {
            id
          }
          amount
        }
        imbalances {
          orderedAmount
          billedAmount
          billedAmounts {
            id
            amount
          }
          orderedAmounts {
            id
            amount
          }
        }
        source {
          document: documentNew2 {
            id
            type
            documentNumber
          }
        }
      }
    }
  }
`;

export const EVENT_OVERVIEW_QUERY = gql`
  query queryEventOverview($businessId: ID!, $from: ISODate, $to: ISODate) {
    orders(businessId: $businessId, fromDate: $from, toDate: $to) {
      nodes {
        id
        supplier {
          id
          name
        }
        products {
          quantity
        }
        amount
        diffs {
          fieldPath
        }
      }
    }

    deliveries(businessId: $businessId, fromDate: $from, toDate: $to) {
      nodes {
        id
        supplier {
          id
          name
        }
        products {
          quantity
        }
        diffs {
          supplierValue
        }
        imbalances {
          productId
        }
      }
    }

    billings(businessId: $businessId, fromDate: $from, toDate: $to) {
      nodes {
        id
        supplier {
          id
          name
        }
        netAmount
        type
        taxAmount
        totalAmount
        deliveryRefs {
          delivery {
            id
          }
        }
        imbalances {
          billedAmount
        }
      }
    }
  }
`;

export const PDF_GENERATE_MUTATION = gql`
  mutation pdfGenerate($template: String!, $data: JSONObject!) {
    pdfGenerate(template: $template, data: $data) {
      url
    }
  }
`;
