import { isNil, uniq, pick, prop, map, pipe, reject, flatten } from 'ramda';
import i18n from '@/imports/startup/client/i18n';

export const getDiffsTypes = (order) => {
  const types = [];
  order.diffs.forEach((diff) => {
    let type = diff.fieldPath.split('.')[2];
    if (type === 'discount') {
      type = 'price';
    }
    types.push(type);
  });
  return uniq(types);
};

export const createDifferenceRow = (order) => {
  const diffsGroupedByProductIndex = groupDiffsByProductIndex(order);
  const totalDifference = sumTotalDifferences(diffsGroupedByProductIndex);

  return {
    id: order.id,
    date: order.date,
    eventReferences: order.eventReferences,
    orderProducts: diffsGroupedByProductIndex.map(({ orderProduct }) => orderProduct),
    totalDifference,
    totalDifferenceAgorot: Math.round(totalDifference * 100),
    totalPriceDifference: sumTotalPriceDifferences(diffsGroupedByProductIndex),
    totalQuantityDifference: sumTotalQuantityDifferences(diffsGroupedByProductIndex),
    diffTypes: getDiffsTypes(order),
    delivery: order.delivery,
  };
};

const DIFF_FIELDS = ['quantity', 'price', 'discount'];
const cleanDiffs = pipe(pick(DIFF_FIELDS), reject(isNil));

export const groupDiffsByProductIndex = (order) => {
  return flatten(
    order.differences.map((diff) => {
      const diffs = [];
      const pricingDiffExists = diff.price || diff.discount;
      if (pricingDiffExists) diffs.push(createOrderItem(order, diff, pricingDiffExists));
      if (diff.quantity) diffs.push(createOrderItem(order, diff));
      return diffs;
    })
  );
};

export const sumTotalDifferences = (diffsGroupedByProductIndex) => {
  return Number(
    diffsGroupedByProductIndex
      .reduce((acc, { orderProduct }) => {
        return acc + orderProduct.amount;
      }, 0)
      .toFixed(2)
  );
};

export const sumTotalPriceDifferences = (diffsGroupedByProductIndex) => {
  return sumTotalDifferences(diffsGroupedByProductIndex.filter((diff) => diff.orderProduct.type === 'pricing'));
};

export const sumTotalQuantityDifferences = (diffsGroupedByProductIndex) => {
  return sumTotalDifferences(diffsGroupedByProductIndex.filter((diff) => diff.orderProduct.type === 'quantity'));
};

export const navigateMonth = (currentDate, jumps) => {
  const date = new Date(currentDate);
  date.setMonth(date.getMonth() + jumps);
  return getISOMonth(date);
};

export const getISOMonth = (date) => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, 0)}`;

const createOrderItem = (order, diff, isPricingDiff) => {
  const orderItem = order.products.find(({ id }) => id === diff.customerItemId);
  return {
    orderProduct: {
      ...pick(['name', 'sku'], diff.product ?? { name: i18n.t('creditMatchModal.matching.general'), sku: '-' }),
      amount: isPricingDiff ? diff.pricingAmount : diff.quantityAmount,
      type: isPricingDiff ? 'pricing' : 'quantity',
      quantity: isPricingDiff ? '-' : diff.quantity.supplierValue - diff.quantity.customerValue,
    },
    customerValues: pick(DIFF_FIELDS, orderItem || { quantity: 0, price: 0 }),
    supplierValues: {
      ...pick(DIFF_FIELDS, orderItem || { quantity: 0, price: 0 }),
      ...map(prop('supplierValue'), cleanDiffs(diff)),
    },
  };
};
