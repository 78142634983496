<template>
  <div class="supplier-order-table">
    <div class="d-flex mb-2 p-2 gap-7 border rounded bg-light-gray text-typography-primary">
      <p>
        {{ $t('commons.reference') }}:
        <span v-if="order.eventReferences">
          <Button type="link" class="p-0 m-0" @click.stop="$emit('document-open', order.eventReferences[0].documentId)">
            {{ $t(`document.exports.schema.type.shortName.${order.eventReferences[0].document.type}`) }}
            {{ order.eventReferences[0].reference }}
          </Button>
        </span>
        <span v-else>-</span>
      </p>
      <p>
        {{ `${$t('commons.date')}: ${formatDate(order.date)}` }}
      </p>
      <p>
        {{ `${$t('commons.total')}: ${formatMoney(order.netAmount)}` }}
      </p>
    </div>
    <Table :columns="columns" :data="order.products" rounded border :hover="false">
      <template
        #cell-sku="{
          rowData: {
            product: { sku },
          },
        }"
      >
        {{ sku }}
      </template>
      <template
        #cell-product="{
          rowData: {
            product: { name },
          },
        }"
        >{{ name }}
      </template>
      <template #cell-quantity="{ rowData: { quantity } }">{{ formatQuantity(quantity) }}</template>
      <template #cell-totalAmount="{ rowData: { totalAmount } }">{{ formatMoney(totalAmount) }}</template>
    </Table>
  </div>
</template>

<script>
import { currency } from '@/locale/numberConfig';
import { options } from '@/locale/dateConfig';
import { Table, Button } from '@/modules/core';

const TABLE_HEADER = {
  SKU: 'sku',
  PRODUCT: 'product',
  QUANTITY: 'quantity',
  TOTAL: 'totalAmount',
};

export default {
  components: {
    Table,
    Button,
  },
  props: {
    order: { type: Object, required: true },
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('commons.sku'),
          key: TABLE_HEADER.SKU,
        },
        {
          header: this.$t('commons.product'),
          key: TABLE_HEADER.PRODUCT,
        },
        {
          header: this.$t('commons.quantity'),
          key: TABLE_HEADER.QUANTITY,
        },
        {
          header: this.$t('commons.total'),
          key: TABLE_HEADER.TOTAL,
        },
      ];
    },
  },
  methods: {
    formatMoney(value) {
      return typeof value === 'number' && !Number.isNaN(value)
        ? Number(value).toLocaleString(this.$i18n.locale, currency)
        : '-';
    },
    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, options.short) : null;
    },
    formatQuantity(number) {
      return typeof number === 'number' && !Number.isNaN(number)
        ? Number(number).toLocaleString(this.$i18n.locale)
        : null;
    },
  },
};
</script>
