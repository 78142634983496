import { computed, ref } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { DateTime } from 'luxon';

import { navigateMonth } from '../utils';

export const useOrders = (supplierId, businessId, defaultMonth) => {
  const month = ref(defaultMonth);
  const range = computed(() => {
    const start = DateTime.fromISO(month.value).startOf('month').endOf('day').toJSDate();
    const end = DateTime.fromISO(month.value).endOf('month').toJSDate();
    return { start, end };
  });
  const isoRange = computed(() => {
    const start = DateTime.fromISO(month.value).startOf('month').toISODate();
    const end = DateTime.fromISO(month.value).endOf('month').toISODate();
    return { start, end };
  });
  const { result, loading } = useQuery(ORDER_DIFFERENCES_QUERY, () => {
    const { start: from, end: to } = isoRange.value;
    return { businessId: businessId.value, supplierId: supplierId.value, from, to };
  });
  const orders = computed(() => result.value?.orders?.nodes ?? []);

  return {
    orders,
    range,
    loading,
    goForward: () => {
      month.value = navigateMonth(month.value, 1);
    },
    goBackward: () => {
      month.value = navigateMonth(month.value, -1);
    },
  };
};

const ORDER_DIFFERENCES_QUERY = gql`
  query orders($businessId: ID!, $supplierId: ID, $from: ISODate, $to: ISODate) {
    orders(businessId: $businessId, supplierId: $supplierId, fromDate: $from, toDate: $to) {
      nodes {
        id
        date
        eventReferences {
          documentId
          reference
        }
        products {
          id
          product {
            id
            sku
            name
          }
          quantity
          price
          discount
          associatedTo
        }
        diffs {
          fieldPath
          customerValue
          supplierValue
        }
        differences {
          customerItemId
          product {
            id
            name
            sku
          }
          quantity {
            customerValue
            supplierValue
            solved
          }
          netPrice {
            customerValue
            supplierValue
            solved
          }
          price {
            customerValue
            supplierValue
            solved
          }
          discount {
            customerValue
            supplierValue
            solved
          }
          amount
          pricingAmount
          quantityAmount
        }
      }
    }
  }
`;
